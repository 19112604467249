* {
    padding: 0;
    margin: 0;
}

.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: white;
}

.episode_container {
    text-align: center;
}

.view-more {
    text-align: center;
    margin-top: 10px;
}

.view-more:hover a {
    text-decoration: none;
    color: white;
}


/* @media screen and (max-width: 785px) {
    li {
        width: 24%;
    }

    .logo {
        width: 150px;
    }

    nav ul li h2 {
        font-size: 25px;
    }

    .header h2 {
        font-size: 30px;
    }
} */

@media screen and (max-width: 785px) {
    .logo {
        width: 150px;
    }

    nav ul li h2 {
        font-size: 25px;
    }

    ul {
        justify-content: center;
    }

     li {
         width: 24%;
     }

     .social_icons_div {
         justify-content: space-between;
     }

     .social_icon {
         width: auto;
     }

     i.fab.fa-patreon {
        font-size: 20px;
    }

    svg.bi {
        font-size: 20px;
    }

    .header h2 {
        font-size: 30px;
    }
}

@media screen and (max-width: 675px) {
    .logo {
        width: 120px;
    }

    nav ul li h2 {
        font-size: 24px;
    }

    i.fab.fa-patreon {
        font-size: 20px;
    }

    svg.bi, svg.svg-inline--fa.fa-patreon.fa-w-16 {
        width: 20px !important;
    }

    .header h2 {
        font-size: 25px;
    }

}
@media screen and (max-width: 500px) {
    button {
        height: 40px;
        width: 100px;
        font-size: 16px;
    }

    ul {
        flex-direction: column;
        align-items: center;
    }

    li {
        width: auto;
        margin-bottom: 20px;
    }

    .logo {
        width: 200px;
    }

    nav ul li h2 {
        font-size: 30px;
    }

    .social_icon {
        width: auto;
        margin-right: 15px;
    }

    .header h2 {
        font-size: 24px;
    }

    iframe {
        width: 370px;
        height: 200px;
    }
}