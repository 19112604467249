
nav {
    width: 100%;
}

.logo {
    width: 200px;
    height: auto;
    object-fit: contain;
}

/* img:hover {
    transform: scale(1.2%);
} */

ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
    justify-content: space-around;
}

li {
    width: 20%;
    font-family: 'Prompt', sans-serif;
    color: white;
}

a {
    text-decoration: none;
    color: white;
}

a:hover{
    color: black;
}

i.fab.fa-patreon {
    font-size: 26px;
}

.social_icons_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.social_icon {
    width: 20%;
}

.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 2em !important;
    width: 2em !important;
    overflow: visible;
    vertical-align: -0.125em;
}