.header {
    text-align: center;
    margin: 2em 0;
    color: white;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.episodes_container {
    width: 30%;
    margin-bottom: 20px;
}

.video_container {
    width: 300px;
}

.view-more {
    text-align: center;
    margin: 10px 0;
}

.view-more:hover a {
    text-decoration: none;
    color: white;
}

@media screen and (max-width:991px) {
    .video_container {
        height: 110px;
        width: 220px;
    }
}

@media screen and (max-width:767px) {
    .video_container {
        height: 110px;
        width: 160px;
    }
}
@media screen and (max-width: 600px){
    .container {
        flex-direction: column;
        align-items: center;
    }

    .episodes_container {
        width: unset;
    }

    .video_container {
        height: 200px;
        width: 355px;
    }
}

