
.form_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form_header{
    text-align: center;
    margin: 0 auto 0.5rem;
    width: 66%;
}

.form-group {
    display: flex;
    justify-content: center;
}

.form {
    margin: 25px 0;
}
textarea.form-control {
    width: 50%;
    background: white;
}

.submit_btn {
    display: flex;
    margin-top: 1em;
    justify-content: center;
}

button {
    border-radius: 7px;
    background: #29ABE2;
    color: white;
    height: 50px;
    width: 150px;
    font-size: 18px;
    cursor: pointer;
}

button:hover{
    transform: scale(1.1);
}

.error_message {
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width: 921px){
    .form_header{
        font-size: 30px;
    }
}

@media screen and (max-width: 864px){
    .form_header{
        width: 80%;
    }
}

@media screen and (max-width: 700px){
    .form_header{
        font-size: 28px;
        width: 85%;
    }
}

@media screen and (max-width: 626px){
    .form_header{
        font-size: 26px;
        width: 85%;
    }

    textarea.form-control {
        width: 75%;
    }
}

@media screen and (max-width: 577px){
    .form_header{
        width: 90%;
    }
}

@media screen and (max-width: 549px){
    .form_header{
        width: 100%;
    }
    
    textarea.form-control {
        width: 80%;
    }
}

@media screen and (max-width: 497px){
    .form_header{
        font-size: 24px;
        width: 92%;
    }
}

@media screen and (max-width: 480px){
    textarea.form-control {
        width: 90%;
    }
}
