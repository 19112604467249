@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");

body {
	font-family: "Baloo 2", cursive;
	font-size: 16px;
	color: #ffffff;
	text-rendering: optimizeLegibility;
	font-weight: initial;
}

#pageHeaderTitle {
	margin: 2rem 0;
	text-transform: uppercase;
	text-align: center;
	font-size: 2.5rem;
}

.postcard {
	flex-wrap: wrap;
	display: flex;
	box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
	border-radius: 10px;
	margin: 0 0 2rem 0;
	overflow: hidden;
	position: relative;
	color: #ffffff;
}

.postcard__img {
    max-height: 400px;
    width: 100%;
    object-fit: cover;
    position: relative;
}

.postcard__img_link {
    display: contents;
}

.postcard__bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #424242;
    transition: width 0.2s ease;
}

.postcard__text {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
}

.postcard__preview-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
}   

.postcard__tagbox {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    margin: 20px 0 0 0;
    padding: 0;
    justify-content: center;
    text-align: center;
}

.tag__item {
    display: inline-block;
    /* background: rgba(83, 83, 83, 0.4); */
    border-radius: 3px;
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    cursor: default;
    user-select: none;
    transition: background-color 0.3s;
}



